import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Navbar.css';

const Navbar = ({ currentUser, onLogout }) => {
  const navigate = useNavigate();
  const location = useLocation(); // To determine active link
  
  const handleLogout = () => {
    if (onLogout) {
      onLogout();
    }
  };
  
  // Helper to determine if a link is active
  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };
  
  return (
    <nav className="navbar">
      <div className="navbar-section left">
        {currentUser && (
          <div className="user-info">
            <span className="username">{currentUser.username}</span>
            <button onClick={handleLogout} className="logout-button">
              Logout
            </button>
          </div>
        )}
      </div>
      
      <div className="navbar-section center">
        <Link to="/" className="nav-brand">
          Sjukt stenhårt gym
        </Link>
      </div>
      
      <div className="navbar-section right">
        {currentUser ? (
          <>
            <Link to="/my-lifts" className={`nav-link ${isActive('/my-lifts')}`}>
              Your Lifts
            </Link>
            <Link to="/leaderboard" className={`nav-link ${isActive('/leaderboard')}`}>
              Leaderboard
            </Link>
            <Link to="/competition/progress" className={`nav-link ${isActive('/competition/progress')}`}>
              Competition
            </Link>
            {/* Show admin link only for user ID 1 (or your admin logic) */}
            {currentUser && currentUser.id === 1 && (
              <Link to="/competition/admin" className={`nav-link ${isActive('/competition/admin')}`}>
                Admin
              </Link>
            )}
          </>
        ) : (
          <>
            <Link to="/" className={`nav-link ${isActive('/')}`}>
              Login
            </Link>
            <Link to="/register" className={`nav-link ${isActive('/register')}`}>
              Register
            </Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
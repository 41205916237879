import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Stickman.css';

const Stickman = () => {
  const [showSecretMessage, setShowSecretMessage] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  // Add some fun interactive effects for the Easter egg
  const handleStickmanClick = () => {
    setClickCount(prev => prev + 1);
    
    // After 5 clicks, show a secret message
    if (clickCount >= 4) {
      setShowSecretMessage(true);
    }
  };

  useEffect(() => {
    // Add "found easter egg" message to console
    console.log("You found the stickman easter egg! 🎮 🥚");
  }, []);

  return (
    <div className="stickman-page">
      <h2>You found the secret stickman!</h2>
      
      <div className="weightlifter-container" onClick={handleStickmanClick}>
        <div className="weightlifter animated">
          <div className="head"></div>
          <div className="body"></div>
          <div className="arm left-arm"></div>
          <div className="arm right-arm"></div>
          <div className="leg left-leg"></div>
          <div className="leg right-leg"></div>
          <div className="barbell"></div>
          <div className="weight left-weight"></div>
          <div className="weight right-weight"></div>
        </div>
      </div>
      
      <div className="click-counter">
        Clicks: {clickCount} {clickCount >= 5 ? "- Good job!" : ""}
      </div>
      
      {showSecretMessage && (
        <div className="secret-message">
          <p>🏆 Congratulations! You've unlocked the secret message! 🏆</p>
          <p>"No pain, no gain! Keep lifting, stickman believer!"</p>
        </div>
      )}
      
      <div className="stickman-navigation">
        <Link to="/" className="back-link">Back to Login</Link>
        {/* Show leaderboard link if user is logged in */}
        {localStorage.getItem('currentUser') && (
          <Link to="/leaderboard" className="back-link">View Leaderboard</Link>
        )}
      </div>
    </div>
  );
};

export default Stickman;
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import base_url from '../../config';
import './Register.css';

const Register = () => {
  const [userData, setUserData] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    secretAnswer: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!userData.username || !userData.password || !userData.secretAnswer) {
      setError('Please fill in all required fields');
      return;
    }
    
    if (userData.password !== userData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    // Check the secret answer (case-insensitive)
    if (userData.secretAnswer.toLowerCase() !== 'lakabufasa') {
      setError('Tyvärr, det svaret är fel. Du är inte tillräckligt stenhård för att få vara med');
      return;
    }
    
    setError('');
    setIsLoading(true);
    
    try {
      const response = await fetch(`${base_url}/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: userData.username,
          password: userData.password
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.detail || `Registration failed: ${response.statusText}`);
      }
      
      const newUser = await response.json();
      console.log('Registration successful! Lakabufasa has granted you entry!:', newUser);
      
      // Show success message and redirect to login
      alert('Registration successful! Please log in with your new account.');
      navigate('/');
    } catch (err) {
      console.error('Registration error:', err);
      
      // Handle specific error cases
      if (err.message.includes('already exists')) {
        setError('This username already exists');
      } else {
        setError(err.message || 'Failed to register. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="register-container">
      <div className="register-form-container">
        <h2>Create an Account</h2>
        {error && <div className="error-message">{error}</div>}
        
        <form onSubmit={handleSubmit} className="register-form">
          <div className="form-group">
            <label htmlFor="username">Username*</label>
            <input
              type="text"
              id="username"
              name="username"
              value={userData.username}
              onChange={handleChange}
              placeholder="Choose a username"
              disabled={isLoading}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Password*</label>
            <input
              type="password"
              id="password"
              name="password"
              value={userData.password}
              onChange={handleChange}
              placeholder="Create a password"
              disabled={isLoading}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password*</label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={userData.confirmPassword}
              onChange={handleChange}
              placeholder="Confirm your password"
              disabled={isLoading}
              required
            />
          </div>
          
          {/* Add the fun security question */}
          <div className="form-group secret-question">
            <label htmlFor="secretAnswer">Vad är Adams smeknamn i vår sjukt stenhårda chat?</label>
            <input
              type="text"
              id="secretAnswer"
              name="secretAnswer"
              value={userData.secretAnswer}
              onChange={handleChange}
              placeholder="Bara de som vet får vara med..."
              disabled={isLoading}
              required
            />
          </div>
          
          <button 
            type="submit" 
            className="register-button"
            disabled={isLoading}
          >
            {isLoading ? 'Creating Account...' : 'Create Account'}
          </button>
        </form>
        
        <div className="register-footer">
          <p>Already have an account? <Link to="/">Log in</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Register;
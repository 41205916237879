import React, { useState, useEffect } from 'react';
import base_url from '../../config';
import './Leaderboard.css';

const Leaderboard = () => {
  const [personalRecords, setPersonalRecords] = useState([]);
  const [users, setUsers] = useState({});
  const [exercises, setExercises] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch all users
        const usersResponse = await fetch(`${base_url}/users/`);
        if (!usersResponse.ok) throw new Error('Failed to fetch users');
        const usersData = await usersResponse.json();
        
        // Create a map of user IDs to user objects for easy lookup
        const usersMap = {};
        usersData.forEach(user => {
          usersMap[user.id] = user;
        });
        setUsers(usersMap);
        
        // Fetch all exercises
        const exercisesResponse = await fetch(`${base_url}/exercises/`);
        if (!exercisesResponse.ok) throw new Error('Failed to fetch exercises');
        const exercisesData = await exercisesResponse.json();
        
        // Create a map of exercise IDs to exercise objects
        const exercisesMap = {};
        exercisesData.forEach(exercise => {
          exercisesMap[exercise.id] = exercise;
        });
        setExercises(exercisesMap);
        
        // Fetch all PRs for all users
        const prsPromises = usersData.map(user => 
          fetch(`${base_url}/users/${user.id}/prs/`)
            .then(res => res.ok ? res.json() : [])
        );
        
        const allUsersPrs = await Promise.all(prsPromises);
        
        // Flatten the array of arrays into a single array of PRs
        const allPrs = allUsersPrs.flat();
        
        setPersonalRecords(allPrs);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchData();
  }, []);

  // Group PRs by exercise
  const groupedByExercise = personalRecords.reduce((acc, pr) => {
    if (!acc[pr.exercise_id]) {
      acc[pr.exercise_id] = [];
    }
    acc[pr.exercise_id].push(pr);
    return acc;
  }, {});

  // Find the top records for each exercise
  const getBestRecords = () => {
    const bestRecords = [];
    
    Object.entries(groupedByExercise).forEach(([exerciseId, records]) => {
      // Sort records by weight, highest first
      const sortedRecords = [...records].sort((a, b) => b.weight - a.weight);
      
      // Take top 3 records for this exercise
      sortedRecords.slice(0, 3).forEach((record, index) => {
        bestRecords.push({
          ...record,
          rank: index + 1,
          exerciseName: exercises[exerciseId]?.name || 'Unknown Exercise'
        });
      });
    });
    
    return bestRecords;
  };

  if (isLoading) {
    return <div className="loading">Loading leaderboard data...</div>;
  }
  
  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  const bestRecords = getBestRecords();

  return (
    <div className="leaderboard-container">
      <h2>Personal Records Leaderboard</h2>
      
      <div className="leaderboard-table-container">
        <table className="leaderboard-table">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Exercise</th>
              <th>User</th>
              <th>Weight (kg)</th>
              <th>Reps</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {bestRecords.length > 0 ? (
              bestRecords.map((record, index) => (
                <tr key={record.id || index}>
                  <td>{record.rank}</td>
                  <td>{record.exerciseName}</td>
                  <td>{users[record.user_id]?.username || 'Unknown User'}</td>
                  <td>{record.weight}</td>
                  <td>{record.reps}</td>
                  <td>{new Date(record.date).toLocaleDateString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="empty-message">No personal records found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Leaderboard;
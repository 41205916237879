import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Add Link import
import base_url from '../../config';
import './Login.css';

const Login = ({ onLoginSuccess }) => {
  const [credentials, setCredentials] = useState({
    username: '',  // Changed from email to username
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Hook for navigation

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Basic validation
    if (!credentials.username || !credentials.password) {  // Changed from email to username
      setError('Please enter both username and password');
      return;
    }
    
    setError('');
    setIsLoading(true);
    
    try {
      // Since your backend doesn't have a specific login endpoint yet,
      // we'll check if the user exists by querying all users and finding a match
      // Note: This is not secure for production! Only for development purposes
      const response = await fetch(`${base_url}/users/`);
      
      if (!response.ok) {
        throw new Error('Failed to connect to the server');
      }
      
      const users = await response.json();
      
      // Find user by username instead of email
      const user = users.find(user => user.username === credentials.username);
      
      if (user) {
        // In a real app, we would verify the password on the server
        // For now, we'll just simulate a successful login
        console.log('Login successful:', user);
        
        const userData = {
          id: user.id,
          username: user.username
        };
        
        // Store user info in localStorage
        localStorage.setItem('currentUser', JSON.stringify(userData));
        
        // Update parent component if needed
        if (onLoginSuccess) {
          onLoginSuccess(userData);
        }
        
        // Navigate to the leaderboard page
        navigate('/leaderboard');
      } else {
        setError('Invalid username or password');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError(err.message || 'Failed to login. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <h2>Login to SSH Gym</h2>
        {error && <div className="error-message">{error}</div>}
        
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="username">Username</label> 
            <input
              type="text"  
              id="username"  
              name="username"  
              value={credentials.username}  
              onChange={handleChange}
              placeholder="Enter your username"  
              disabled={isLoading}
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              placeholder="Enter your password"
              disabled={isLoading}
            />
          </div>
          
          <button 
            type="submit" 
            className="login-button"
            disabled={isLoading}
          >
            {isLoading ? 'Logging in...' : 'Login'}
          </button>
        </form>
        
        <div className="login-footer">
          <p>Don't have an account? <Link to="/register">Sign up</Link></p>
          <p><Link to="/forgot-password">Forgot password?</Link></p>
        </div>
      </div>
    </div>
  );
};

export default Login;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import base_url from '../../config';
import './CompetitionProgress.css';

const CompetitionProgress = () => {
  const [progressData, setProgressData] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataFetched, setDataFetched] = useState(false); // Add flag to control fetching
  const navigate = useNavigate();
  
  // Get current user
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  
  // Authentication check
  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);
  
  // Data fetching with control flag
  useEffect(() => {
    // Only fetch if user is authenticated and data hasn't been fetched yet
    if (currentUser && !dataFetched) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          console.log('Fetching competition data...');
          
          // Fetch exercises for names
          const exercisesResponse = await fetch(`${base_url}/exercises/`);
          if (!exercisesResponse.ok) throw new Error('Failed to fetch exercises');
          const exercisesData = await exercisesResponse.json();
          setExercises(exercisesData);
          
          // Fetch competition progress
          const progressResponse = await fetch(`${base_url}/competition/progress`);
          if (!progressResponse.ok) {
            const errorData = await progressResponse.json().catch(() => null);
            throw new Error(errorData?.detail || 'Failed to fetch competition progress');
          }
          
          const progressData = await progressResponse.json();
          setProgressData(progressData);
          setDataFetched(true); // Mark data as fetched
        } catch (err) {
          console.error('Error fetching data:', err);
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchData();
    }
  }, [currentUser, dataFetched]); // Depend on dataFetched flag
  
  // Function to manually refresh data
  const handleRefreshData = () => {
    setDataFetched(false); // Reset flag to trigger a new fetch
  };
  
  // Helper function to get exercise name
  const getExerciseName = (exerciseId) => {
    const exercise = exercises.find(ex => ex.id === exerciseId);
    return exercise ? exercise.name : 'Unknown Exercise';
  };
  
  if (isLoading && !progressData) {
    return (
      <div className="competition-container">
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p>Loading competition data...</p>
        </div>
      </div>
    );
  }
  
  if (error) {
    return (
      <div className="competition-container">
        <div className="competition-header">
          <h2>Competition Progress</h2>
          <button 
            className="refresh-button"
            onClick={handleRefreshData}
            disabled={isLoading}
          >
            {isLoading ? 'Refreshing...' : '↻ Refresh'}
          </button>
        </div>
        
        <div className="error-message">
          {error}
          {error.includes('start date not set') && (
            <p>The competition hasn't started yet. Talk to the admin to set a start date.</p>
          )}
        </div>
      </div>
    );
  }
  
  return (
    <div className="competition-container">
      <div className="competition-header">
        <h2>Competition Progress</h2>
        <div className="header-actions">
          <button 
            className="refresh-button"
            onClick={handleRefreshData}
            disabled={isLoading}
          >
            {isLoading ? 'Refreshing...' : '↻ Refresh'}
          </button>
          <div className="competition-info">
            <p><strong>Started:</strong> {new Date(progressData.start_date).toLocaleDateString()}</p>
            <p><strong>Status:</strong> {progressData.competition_status}</p>
          </div>
        </div>
      </div>
      
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Updating competition data...</p>
        </div>
      )}
      
      <div className="competition-results">
        <h3>Overall Improvement</h3>
        <div className="user-rankings">
          {Object.entries(progressData.results)
            .sort((a, b) => b[1].total_improvement - a[1].total_improvement)
            .map(([username, data], index) => (
              <div key={username} className={`user-rank-card ${index === 0 ? 'first-place' : index === 1 ? 'second-place' : index === 2 ? 'third-place' : ''}`}>
                <div className="rank-position">{index + 1}</div>
                <div className="user-rank-info">
                  <h4 className="user-rank-name">{username}</h4>
                  <div className="user-rank-stats">
                    <div className="rank-stat">
                      <span className="stat-label">Total Improvement</span>
                      <span className="stat-value">{data.total_improvement.toFixed(1)} kg</span>
                    </div>
                    <div className="rank-stat">
                      <span className="stat-label">Average % Increase</span>
                      <span className="stat-value">{data.total_improvement_percent.toFixed(1)}%</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      
      <div className="exercise-improvements">
        <h3>Improvements By Exercise</h3>
        {Object.entries(progressData.results).map(([username, data]) => (
          <div key={username} className="user-exercise-section">
            <h4 className="user-exercise-header">{username}'s Improvements</h4>
            <div className="exercise-cards">
              {data.improvements.length > 0 ? (
                data.improvements.map((improvement, index) => (
                  <div key={index} className="exercise-improvement-card">
                    <div className="exercise-name">
                      {getExerciseName(improvement.exercise_id)}
                    </div>
                    <div className="improvement-details">
                      <div className="improvement-column">
                        <h5>Starting PR</h5>
                        <div className="pr-detail">{improvement.start_weight} kg × {improvement.start_reps} reps</div>
                        <div className="pr-detail">Est. 1RM: {improvement.start_orm.toFixed(1)} kg</div>
                      </div>
                      <div className="improvement-column">
                        <h5>Current PR</h5>
                        <div className="pr-detail">{improvement.current_weight} kg × {improvement.current_reps} reps</div>
                        <div className="pr-detail">Est. 1RM: {improvement.current_orm.toFixed(1)} kg</div>
                      </div>
                      <div className="improvement-result">
                        <h5>Improvement</h5>
                        <div className="improvement-value">+{improvement.improvement.toFixed(1)} kg</div>
                        <div className="improvement-percent">+{improvement.improvement_percent.toFixed(1)}%</div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-improvements">No improvements recorded yet</div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompetitionProgress;
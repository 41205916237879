import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import base_url from '../../config';
import './CompetitionAdmin.css';

const CompetitionAdmin = () => {
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentSettings, setCurrentSettings] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [dataFetched, setDataFetched] = useState(false); // Add flag to control fetching
  const navigate = useNavigate();
  
  // Get current user
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  
  // Check if user is admin (you can define admins however you want)
  // For now, let's assume user ID 1 is the admin
  const isAdmin = currentUser && currentUser.id === 1;
  
  // First useEffect only for authorization check
  useEffect(() => {
    if (!currentUser) {
      navigate('/');
      return;
    }
    
    if (!isAdmin) {
      navigate('/my-lifts');
      return;
    }
  }, [currentUser, isAdmin, navigate]);
  
  // Second useEffect only for data fetching with proper control
  useEffect(() => {
    // Only fetch if user is authorized and data not already fetched
    if (currentUser && isAdmin && !dataFetched) {
      const fetchSettings = async () => {
        setIsLoading(true);
        try {
          console.log("Fetching competition settings");
          const response = await fetch(`${base_url}/competition/settings`);
          
          if (response.ok) {
            const data = await response.json();
            setCurrentSettings(data);
            
            // If we have a start date, set it in the form
            if (data.start_date) {
              setStartDate(data.start_date.split('T')[0]);
            }
            
            // If we have an end date, set it in the form
            if (data.end_date) {
              setEndDate(data.end_date.split('T')[0]);
            }
          }
        } catch (err) {
          console.error("Failed to fetch competition settings", err);
          setError("Failed to fetch competition settings. Please try again.");
        } finally {
          setIsLoading(false);
          setDataFetched(true);  // Mark as fetched so we don't fetch again
        }
      };
      
      fetchSettings();
    }
  }, [currentUser, isAdmin, dataFetched]); // Include dataFetched in dependencies
  
  const handleSetCompetitionDates = async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);
    
    try {
      const response = await fetch(`${base_url}/competition/settings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          start_date: startDate,
          end_date: endDate || null
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.detail || 'Failed to set competition dates');
      }
      
      const data = await response.json();
      setCurrentSettings(data);
      setSuccess(true);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  // Function to manually refresh the settings
  const handleRefreshSettings = () => {
    setDataFetched(false);  // This will trigger a refetch
  };
  
  if (!isAdmin) {
    return (
      <div className="admin-container">
        <h2>Access Denied</h2>
        <p>You do not have permission to access this page.</p>
      </div>
    );
  }
  
  return (
    <div className="admin-container">
      <div className="admin-header">
        <h2>Competition Settings</h2>
        <button 
          className="refresh-button"
          onClick={handleRefreshSettings}
          disabled={isLoading}
        >
          {isLoading ? 'Refreshing...' : '↻ Refresh'}
        </button>
      </div>
      
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">Competition settings updated successfully!</div>}
      
      {isLoading && <div className="loading-message">Loading settings...</div>}
      
      {!isLoading && (
        <>
          <div className="current-settings">
            <h3>Current Competition Status</h3>
            {currentSettings ? (
              <div className="settings-info">
                <p><strong>Start Date:</strong> {currentSettings.start_date ? 
                  new Date(currentSettings.start_date).toLocaleDateString() : 'Not set'}</p>
                <p><strong>End Date:</strong> {currentSettings.end_date ? 
                  new Date(currentSettings.end_date).toLocaleDateString() : 'Not set'}</p>
                <p><strong>Status:</strong> {
                  currentSettings.start_date ? (
                    new Date(currentSettings.start_date) > new Date() ? 'Not started yet' : 
                    currentSettings.end_date && new Date(currentSettings.end_date) < new Date() ? 'Completed' : 
                    'In progress'
                  ) : 'Not configured'
                }</p>
              </div>
            ) : (
              <p className="no-settings">No competition has been configured yet.</p>
            )}
          </div>
          
          <div className="settings-form">
            <h3>Update Competition Dates</h3>
            <div className="form-group">
              <label htmlFor="startDate">Start Date*</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                max={endDate || undefined}
                required
              />
              <small>This sets when the competition officially begins.</small>
            </div>
            
            <div className="form-group">
              <label htmlFor="endDate">End Date (Optional)</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min={startDate}
              />
              <small>Set this if your competition has a specific end date.</small>
            </div>
            
            <button 
              className="admin-button"
              onClick={handleSetCompetitionDates}
              disabled={isLoading || !startDate}
            >
              {isLoading ? 'Saving...' : 'Save Competition Settings'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CompetitionAdmin;
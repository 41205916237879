// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import Leaderboard from './components/Leaderboard/Leaderboard';
import Stickman from './components/Stickman/Stickman';
import Navbar from './components/Navbar/Navbar';  // Import the Navbar component
import MyLifts from './components/MyLifts/MyLifts'; // Add this import
import CompetitionAdmin from './components/CompetitionAdmin/CompetitionAdmin'; // Add this import
import CompetitionProgress from './components/CompetitionProgress/CompetitionProgress'; // Add this import
import base_url from './config';
import './App.css';


function App() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    // Check if user is already logged in
    const savedUser = localStorage.getItem('currentUser');
    if (savedUser) {
      setCurrentUser(JSON.parse(savedUser));
    }
    
    // Fetch users data
    fetch(`${base_url}/users/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        setError(err.message);
      });
  }, []);

  // Konami code Easter egg
  useEffect(() => {
    const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    let konamiCodePosition = 0;

    const handleKeyDown = (e) => {
      const key = e.key;
      const requiredKey = konamiCode[konamiCodePosition];
      
      if (key === requiredKey) {
        konamiCodePosition++;
        
        if (konamiCodePosition === konamiCode.length) {
          window.location.href = '/stickman';
          konamiCodePosition = 0;
        }
      } else {
        konamiCodePosition = 0;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('currentUser');
    setCurrentUser(null);
    window.location.href = '/';
  };

  return (
    <Router>
      <div className="App">
        <Navbar currentUser={currentUser} onLogout={handleLogout} />
        
        <div className="content-container">
          <Routes>
            <Route path="/" element={
              currentUser ? <Navigate to="/leaderboard" /> : <Login onLoginSuccess={setCurrentUser} />
            } />
            <Route path="/register" element={<Register />} />
            <Route path="/leaderboard" element={
              currentUser ? <Leaderboard /> : <Navigate to="/" />
            } />
            <Route path="/my-lifts" element={
              currentUser ? <MyLifts /> : <Navigate to="/" />
            } />
            <Route path="/stickman" element={<Stickman />} />
            <Route path="/competition/admin" element={
              currentUser ? <CompetitionAdmin /> : <Navigate to="/" />
            } />
            <Route path="/competition/progress" element={
              currentUser ? <CompetitionProgress /> : <Navigate to="/" />
            } />
          </Routes>
          <Link to="/stickman" className="easter-egg-hint">stickman</Link>
        </div>
      </div>
    </Router>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import base_url from '../../config';
import './MyLifts.css';

const MyLifts = () => {
  const [personalRecords, setPersonalRecords] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [formData, setFormData] = useState({
    exercise_id: '',
    weight: '',
    reps: '',
    date: new Date().toISOString().split('T')[0], // Default to today's date in YYYY-MM-DD format
    notes: ''
  });
  const [dataFetched, setDataFetched] = useState(false);
  const navigate = useNavigate();
  
  // Get current user from localStorage - do this once, outside of useEffect
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  
  // Separate useEffect to redirect if no user is logged in
  useEffect(() => {
    if (!currentUser) {
      navigate('/');
    }
  }, [currentUser, navigate]);

  // Main data fetching useEffect with proper dependency and safeguards
  useEffect(() => {
    // Only fetch if user exists and data hasn't been fetched yet
    if (currentUser && !dataFetched) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          console.log('Fetching data for user:', currentUser.id);
          
          // Fetch all exercises for the dropdown
          const exercisesResponse = await fetch(`${base_url}/exercises/`);
          if (!exercisesResponse.ok) throw new Error('Failed to fetch exercises');
          const exercisesData = await exercisesResponse.json();
          setExercises(exercisesData);
          
          // Fetch user's personal records
          const prsResponse = await fetch(`${base_url}/users/${currentUser.id}/prs/`);
          if (!prsResponse.ok) throw new Error('Failed to fetch personal records');
          const prsData = await prsResponse.json();
          
          // Process PR data with exercises
          const prsWithExerciseDetails = [];
          
          // If we have PRs, fetch exercise details for each
          if (prsData.length > 0) {
            for (const pr of prsData) {
              try {
                const exerciseResponse = await fetch(`${base_url}/exercises/${pr.exercise_id}`);
                if (exerciseResponse.ok) {
                  const exerciseData = await exerciseResponse.json();
                  prsWithExerciseDetails.push({ ...pr, exercise: exerciseData });
                } else {
                  // If we can't get exercise details, just use the PR data alone
                  prsWithExerciseDetails.push(pr);
                }
              } catch (err) {
                console.error('Error fetching exercise details:', err);
                prsWithExerciseDetails.push(pr);
              }
            }
          }
          
          setPersonalRecords(prsWithExerciseDetails);
          setDataFetched(true); // Mark data as fetched
        } catch (err) {
          console.error('Error fetching data:', err);
          setError(err.message);
        } finally {
          setIsLoading(false);
        }
      };
      
      fetchData();
    }
  }, [currentUser, dataFetched]); // Only depend on currentUser and dataFetched flag
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!formData.exercise_id || !formData.weight || !formData.reps || !formData.date) {
      setError('Please fill in all required fields');
      return;
    }
    
    try {
      const payload = {
        exercise_id: parseInt(formData.exercise_id),
        weight: parseFloat(formData.weight),
        reps: parseInt(formData.reps),
        date: new Date(formData.date).toISOString(), // Convert to ISO format
        notes: formData.notes || undefined
      };
      
      const response = await fetch(`${base_url}/users/${currentUser.id}/prs/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload)
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(errorData?.detail || `Failed to save: ${response.statusText}`);
      }
      
      // Get the new PR data
      const newPR = await response.json();
      
      // Find the exercise in our already loaded exercises to avoid another API call
      const existingExercise = exercises.find(ex => ex.id === newPR.exercise_id);
      
      // Update personal records list
      setPersonalRecords([...personalRecords, {
        ...newPR,
        exercise: existingExercise
      }]);
      
      // Reset form
      setFormData({
        exercise_id: '',
        weight: '',
        reps: '',
        date: new Date().toISOString().split('T')[0], // Default to today's date in YYYY-MM-DD format
        notes: ''
      });
      
      // Hide form
      setShowAddForm(false);
      setError(null);
    } catch (err) {
      console.error('Error saving personal record:', err);
      setError(err.message);
    }
  };
  
  // Create a refresh function for manual refreshing
  const handleRefreshData = () => {
    setDataFetched(false); // This will trigger a new fetch in the useEffect
  };
  
  // Group personal records by exercise category
  const groupedByCategory = personalRecords.reduce((acc, pr) => {
    if (pr.exercise) {
      const category = pr.exercise.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(pr);
    }
    return acc;
  }, {});
  
  if (isLoading) {
    return (
      <div className="my-lifts-container">
        <div className="loading">Loading your personal records...</div>
      </div>
    );
  }
  
  return (
    <div className="my-lifts-container">
      <div className="my-lifts-header">
        <h2>My Personal Records</h2>
        
        <div className="header-buttons">
          {!showAddForm ? (
            <>
              <button 
                className="refresh-button"
                onClick={handleRefreshData}
                disabled={isLoading}
              >
                {isLoading ? 'Refreshing...' : '↻ Refresh'}
              </button>
              <button 
                className="add-pr-button"
                onClick={() => setShowAddForm(true)}
              >
                Add New PR
              </button>
            </>
          ) : (
            <button 
              className="cancel-button"
              onClick={() => {
                setShowAddForm(false);
                setError(null);
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
      
      {error && <div className="error-message">{error}</div>}
      
      {showAddForm && (
        <div className="add-pr-form-container">
          <h3>Add New Personal Record</h3>
          <form onSubmit={handleSubmit} className="add-pr-form">
            <div className="form-group">
              <label htmlFor="exercise_id">Exercise*</label>
              <select
                id="exercise_id"
                name="exercise_id"
                value={formData.exercise_id}
                onChange={handleChange}
                required
              >
                <option value="">Select an exercise</option>
                {exercises.map(exercise => (
                  <option key={exercise.id} value={exercise.id}>
                    {exercise.name}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="weight">Weight (kg)*</label>
                <input
                  type="number"
                  step="0.1"
                  id="weight"
                  name="weight"
                  value={formData.weight}
                  onChange={handleChange}
                  placeholder="e.g., 100"
                  required
                />
              </div>
              
              <div className="form-group">
                <label htmlFor="reps">Reps*</label>
                <input
                  type="number"
                  id="reps"
                  name="reps"
                  value={formData.reps}
                  onChange={handleChange}
                  placeholder="e.g., 5"
                  required
                />
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="date">Date*</label>
              <input
                type="date"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="notes">Notes</label>
              <textarea
                id="notes"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                placeholder="Any additional notes..."
                rows="3"
              ></textarea>
            </div>
            
            <button type="submit" className="submit-button">Save Personal Record</button>
          </form>
        </div>
      )}
      
      <div className="records-section">
        {personalRecords.length > 0 ? (
          Object.entries(groupedByCategory).map(([category, records]) => (
            <div key={category} className="category-section">
              <h3 className="category-title">{category}</h3>
              
              <div className="pr-cards">
                {records.map(record => (
                  <div key={record.id} className="pr-card">
                    <div className="exercise-name">{record.exercise?.name || 'Unknown Exercise'}</div>
                    <div className="pr-details">
                      <div className="pr-stat">
                        <span className="pr-label">Weight:</span>
                        <span className="pr-value">{record.weight} kg</span>
                      </div>
                      <div className="pr-stat">
                        <span className="pr-label">Reps:</span>
                        <span className="pr-value">{record.reps}</span>
                      </div>
                      <div className="pr-stat">
                        <span className="pr-label">Date:</span>
                        <span className="pr-value">{new Date(record.date).toLocaleDateString()}</span>
                      </div>
                    </div>
                    {record.notes && (
                      <div className="pr-notes">
                        <span className="notes-label">Notes:</span>
                        <p>{record.notes}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className="empty-records">
            <p>You don't have any personal records yet. Add your first PR!</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyLifts;